export default {
    user: {
        authenticated: false,
        token: null,
        tokenParsed: null,
        idTokenParsed: null,
        crefotrust: {
            submitting: false,
            submitted: false,
            cancelled: false,
            success: false,
            error: false
        },
        user: {
            salutationCode: null,
            title: null,
            genderCode: null,
            birthdate: null,
            department: null,
            position: null,
            telephoneNumber: null,
            preferredLanguage: null,
            givenName: '',
            surname: '',
            email: null,
            password: null,
            company: null,
            crefonummer: null,
            id: null,
            user_roles: [],
            memberId: null,
            actForMemberId: null,
            actForMemberName: ''
        }
    }
}
