<template>
    <div>
        <router-view />
    </div>
</template>

<script>
import axios from 'axios'
export default {
    methods: {
        setLanguage () {
            let clientLanguage = 'de_DE'

            if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                if (langCode && langCode.length && /^[a-zA-Z_]{2,5}$/.test(langCode)) {
                    clientLanguage = langCode
                }
            }

            this.$i18n.locale = clientLanguage
        },
        checkToken() {
            if (window.keycloak && window.keycloak.token) {
                this.$store.dispatch('userModule/onValidTokenExists', window.keycloak)
                return true
            }
            return false
        },
        checkTokenDelayed(timeout = 100) {
            setTimeout(() => {
                if (!this.checkToken() && timeout * 2 <= 30000) {
                    this.checkTokenDelayed(timeout * 2)
                }
            }, timeout)
        }
    },
    created () {
        if (process && process.env) {
            console.log(`SCS ${process.env.PACKAGE_NAME} ${process.env.PACKAGE_VERSION}`)
            console.log('process.env.VARS: ', process.env.VARS)
        }

        axios.interceptors.request.use(
            async (config) => {
                if (window.keycloak && window.keycloak.token) {
                    let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                    if (tokenExpires < 10) {
                        console.log('[axios] Token expires soon. Updating token before sending request')
                        await window.updateKeycloakToken()
                    } else {
                        console.log('[axios] Token still valid. No need to refresh before sending request')
                    }
                    config.headers.Authorization = 'Bearer ' + window.keycloak.token
                }

                if (sessionStorage.getItem('actForMemberId') !== null) {
                    config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                } else {
                    delete config.headers['X-Act-For-Member-Id']
                }

                return config
            },
            (error) => Promise.reject(error)
        )
    },
    mounted () {
        /**
         * Don't remove any existing code here (unless you're 100% sure what you're doing)!
         */
        if (localStorage && localStorage.getItem('axiosmock')) {
            if (process && process.env && process.env.NODE_ENV === 'development') {
                const matched = require.context('./services', true, /-mock\.js$/)
                matched.keys().forEach(matched)
            }
        }

        if (!this.checkToken()) {
            this.checkTokenDelayed()
        }

        window.addEventListener('languageChanged', this.setLanguage, false)
        this.setLanguage()

        window.addEventListener('loggedIn', () => {
            // Authenticate the user within this microfrontend
            // when a login event was dispatched from another microfrontend
            console.log('loggedIn')
            this.checkToken()
        }, false)

        window.addEventListener('actForMemberChanged', () => {
            let actForMemberId = sessionStorage.getItem('actForMemberId')
            let actForMemberName = sessionStorage.getItem('actForMemberName')

            if (actForMemberId === null || actForMemberName === null ||
                actForMemberId === this.$store.state.userModule.user.user.memberId
            ) {
                this.$store.dispatch('userModule/setActForMember', {
                    actForMemberId: null,
                    actForMemberName: ''
                })
            } else {
                this.$store.dispatch('userModule/setActForMember', {
                    actForMemberId: actForMemberId,
                    actForMemberName: actForMemberName
                })
            }
        }, false)

        window.addEventListener('keycloakTokenUpdated', () => {
            // Update the state and the request interceptor
            // on keycloak token updates
            console.log('keycloakTokenUpdated')
            this.$store.dispatch('userModule/onKeycloakTokenUpdateSuccess', window.keycloak)
        }, false)
    },
    beforeDestroy () {
        window.removeEventListener('languageChanged', this.setLanguage)
    }
}
</script>
