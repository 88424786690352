<template>
    <div>
        <div class="container pt">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <a href="/">{{ $t('shared.breadcrumbs.home') }}</a>
                            </li>
                            <li>
                                <a v-bind="{ 'href': $i18n.locale === 'en_US' ? '/en/my-profile' : '/mein-profil' }">
                                    {{ $t('appname') }}
                                </a>
                            </li>
                            <li>{{ $t('breadcrumbs.digitalIdentity') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand-light-grey">
            <div class="container pt">
                <div class="row">
                    <div class="col-12">
                        <div class="bg-white space-around mb">
                            <h1 data-qa="headline">{{ $t('vbstatus.h1') }}</h1>
                            <img src="@/assets/img/crefotrust-logo.svg" alt="CrefoTrust Logo" class="ct-logo" />

                            <div class="crefotrust-vb-teaser">

                                <div v-if="placeOrderFailed">
                                    <div data-qa="crefotrust--authorized-representative--verified" class="crefo-ui-alert error flex verified">
                                        <i class="crefo-ui-icon icon-circle-error" aria-hidden="true"></i>
                                        <span>{{ $t('crefotrust.placeOrderFailed') }}</span>
                                    </div>
                                </div>

                                <div v-if="isPlacingOrder" class="crefo-ui-alert mb info" data-qa="crefotrust--authorized-representative--placing-order">
                                    {{ $t('crefotrust.placingOrder') }}
                                </div>

                                <div v-else>
                                    <div class="row mb">
                                        <div class="col-12">
                                            <p>{{ $t('vbstatus.teaser.intro1') }}</p>
                                        </div>
                                        <div class="col-12 col-md-8">
                                            <p v-html="$t('vbstatus.teaser.intro2')"></p>
                                            <ul class="list-unordered list-checked">
                                                <li>{{ $t('vbstatus.teaser.intro2.bulletlist.item1') }}</li>
                                                <li>{{ $t('vbstatus.teaser.intro2.bulletlist.item2') }}</li>
                                                <li>{{ $t('vbstatus.teaser.intro2.bulletlist.item3') }}</li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <img class="teaser-image" alt="CrefoTrust" width="320" height="213" src="@/assets/img/teaser-crefotrust-verifizierung.jpg"/>
                                        </div>
                                        <div class="col-12">
                                            <button
                                                data-qa="crefotrust--authorized-representative--start-verification-btn"
                                                class="btn btn-default mb-small"
                                                @click.prevent="preInitCrefoTrustVerification()"
                                                :disabled="!isVerificationEnabled"
                                            >
                                                <span v-if="!isActingForOtherMemberId">{{ $t('crefotrust.button') }}<sup>*</sup></span>
                                                <Tooltip v-else position="right" text="Um sich als vertretungsberechtigt für Ihr Unternehmen zu verifizieren, müssen Sie in Ihrer originären Mitgliedsnummer eingeloggt sein. Bitte nutzen Sie zum Wechsel die Unternehmensnavigation.">{{ $t('crefotrust.button') }}<sup>*</sup></Tooltip>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row mb">
                                        <div class="col-12">
                                            <h3>{{ $t('vbstatus.teaser.section1.headline') }}</h3>
                                            <p>{{ $t('vbstatus.teaser.section1.text') }}</p>
                                        </div>
                                    </div>

                                    <div class="row mb">
                                        <div class="col-12">
                                            <h3>{{ $t('vbstatus.teaser.section2.headline') }}</h3>
                                            <p v-html="$t('vbstatus.teaser.section2.text')"></p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-small text-italic text-brand-dark-grey" v-html="agbText"></p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="crefotrust-verified-agent">
                                <div v-if="isVerifiedAgent" data-qa="verified-agent" class="crefo-ui-alert success flex verified">
                                    <i class="crefo-ui-icon icon-check" aria-hidden="true"></i>
                                    <span>{{ $t('crefotrust.agent.verified') }}</span>
                                </div>
                            </div>


                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Tooltip from '@/components/tooltip'
import * as api from '@/services/index.js'
import {dispatchEvent} from "@/helper/polyfill";

export default {
    components: {
        Tooltip
    },
    name: 'VbVerificationView',
    data () {
        return {
            isPlacingOrder: false,
            placeOrderFailed: false,
            isVerifiedAgent: false,
            isVerifyingAgent: true
        }
    },
    mounted () {
        if (!this.$store.state.userModule.user.tokenParsed?.cr_userrole.filter((role) => role.toLowerCase().indexOf('unternehmensagent') !== -1).length) {
            console.log('[DID] VB View (verified agent): Rolle Unternehmensagent existiert nicht, forciere Tokenupdate..')

            // Force a token update, first.
            // This is required for users who view the page for the first time,
            // after they've finished the verification process.
            dispatchEvent('updateKeycloakToken')

            /* istanbul ignore next */
            this.$store.watch((state) => state.userModule.user.token, (newProps, oldProps) => {
                if (newProps && newProps !== oldProps) {
                    this.isVerifyingAgent = false
                    console.log('[DID] VB View (verified agent): Token aktualisiert..')

                    if (this.$store.state.userModule.user.tokenParsed.cr_userrole.filter((role) => role.toLowerCase().indexOf('unternehmensagent') !== -1).length) {
                        this.isVerifiedAgent = true
                        console.log('[DID] VB View (verified agent): Rolle Unternehmensagent existiert.')
                    } else {
                        console.log('[DID] VB View (verified agent): Rolle Unternehmensagent existiert nicht.')
                    }
                }
            })
        } else {
            console.log('[DID] VB View (verified agent): Rolle Unternehmensagent existiert bereits, kein Tokenupdate notwendig.')
            this.isVerifiedAgent = true
            this.isVerifyingAgent = false
        }
    },
    computed: {
        ...mapState([
            'userModule'
        ]),
        agbText: function () {
            let tosLink = require('@/assets/pdf/AGB-CrefoTrust.pdf').default // path to Terms of Services
            let privacyLink = require('@/assets/pdf/Datenschutzerklaerung-CrefoTrust.pdf').default // path to Privacy Policy
            return this.$i18n.t('crefotrust.teaser.agb.text', [tosLink, privacyLink])
        },
        isActingForOtherMemberId: function () {
            let actForMemberId = sessionStorage.getItem('actForMemberId')
            let actForMemberName = sessionStorage.getItem('actForMemberName')
            if (actForMemberId !== null && actForMemberName !== null) {
                return true
            }

            return false
        },
        isSubmitting: function () {
            return this.$store.state.userModule.user.crefotrust.submitting === true
        },
        isVerificationEnabled: function () {
            return !this.isSubmitting && !this.isActingForOtherMemberId
        }
    },
    methods: {
        /* istanbul ignore next */
        preInitCrefoTrustVerification () {
            let self = this
            let content = this.$t('vbstatus.teaser.modal.text')
            let buttons = {
                cancel: self.$i18n.t('vbstatus.teaser.modal.cta.abort'),
                confirm: self.$i18n.t('vbstatus.teaser.modal.cta.ok')
            }

            CrefoUI.Modal.show({
                title: self.$i18n.t('vbstatus.teaser.modal.headline'),
                content,
                buttons: buttons,
                confirmCallback: function (e) {
                    self.initCrefoTrustVerification()
                },
                cancelCallback: function (e) {
                    // alert('Modal geschlossen: Abbrechen')
                },
                dataQa: 'start-crefotrust'
            })
        },
        /* istanbul ignore next */
        async initCrefoTrustVerification () {
            // Scrolle zum Seitenanfang, damit die Statusanzeige im Viewport ist
            window.scroll({top: 0, left: 0, behavior: 'smooth'})

            this.isPlacingOrder = true
            this.placeOrderFailed = false

            // Fetch env vars for Crefotrust
            let $env = {}
            if (process && process.env && process.env.VARS) {
                $env = process.env.VARS
            }
            console.log('ENV_VARS', $env)

            const orderPayload = {
                "clientId": $env.CREFOTRUST_CLIENT_ID
            }
            console.log('[CrefoTrust] Trying to place order', orderPayload)

            this.trackWebtrekk(
                'crefotrust_verification_place_order_started'
            )

            const handleError = (msg) => {
                console.log('[CrefoTrust] Error placing order', msg)
                this.placeOrderFailed = true
                this.trackWebtrekk(
                    'crefotrust_verification_place_order_failed'
                )
                this.isPlacingOrder = false
            }

            try {
                let response = await api.placeOrder($env.DIGITAL_IDENTITY_API_BASEURL, orderPayload)
                if (response && response.data && response.data.redirectUrl) {
                    this.trackWebtrekk(
                        'crefotrust_verification_started'
                    )
                    console.log(response.data)
                    location.assign(response.data.redirectUrl)

                } else {
                    handleError(response)
                }
            } catch(error) {
                handleError(error)
            }
        },
        /* istanbul ignore next */
        trackWebtrekk (name, parameter3Value) {
            if (window.wtSmart) {
                /**
                 * Since this is a reference to a global function on the window object,
                 * it would trigger a Referenceerror when running in a Unit test context
                 * without the extra if condition.
                 */
                let data = { name: name }
                if (parameter3Value) {
                    data.parameter = {
                        3: parameter3Value
                    }
                }
                wtSmart.action.data.set(data)
                wtSmart.track()
            }
        }
    }
}
</script>

<style lang="less">
.crefo-ui-alert.error a {
    color: #fff !important;
    text-decoration: underline;
}
.crefo-tooltip {
    width: 300px;
}
.flex {
    display: flex;
}
.space-around {
    padding: 15px;
}
.ct-logo {
    width: 150px;
    position: absolute;
    right: 30px;
    top: 30px;
    display: none;
}
@media only screen and (min-width: 768px) {
    .ct-logo {
        display: block;
    }
}
@media only screen and (max-width: 767px) {
    .teaser-image {
        margin-bottom: 30px;
    }
}

.verified {
    span {
        line-height: 23px;
        padding-left: 15px;
    }
}
</style>
