import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { dispatchEvent } from '@/helper/polyfill'

import VbErrorView from '../views/error'
import VbVerifiedView from '../views/verified'
import VbVerificationView from '../views/verification'

Vue.use(VueRouter)

// current valid values are: "/portal" & "/backoffice"
const BASE_PATH_MICROFRONTEND_CONTAINER = '/portal'

const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = 'digitale-identitaet'

// Called in "beforeEnter" on protected routes
const isAuthenticated = (to, from, next, timeout = 200) => {
    if (store.state.userModule.user.authenticated || (window.keycloak && window.keycloak.token)) {
        next()
    } else if (timeout >= 2000) {
        dispatchEvent('redirectToLogin')
    } else {
        setTimeout(() => {
            isAuthenticated(to, from, next, timeout * 2)
        }, timeout)
    }
}

const isNotAuthorizedRepresentative = (to, from, next, timeout = 200) => {
    if (store.state.userModule.user.authenticated || (window.keycloak && window.keycloak.token)) {
        if (window.keycloak.tokenParsed.cr_userrole.filter((role) => role.toLowerCase().indexOf('vertretungsberechtigung') !== -1).length) {
            next({ name: 'vb-verified' })
        } else {
            next()
        }
    } else if (timeout >= 2000) {
        dispatchEvent('redirectToLogin')
    } else {
        setTimeout(() => {
            isNotAuthorizedRepresentative(to, from, next, timeout * 2)
        }, timeout)
    }
}

const routes = [
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}`,
        name: 'index',
        redirect: { name: 'vb-verification' }
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vb-verifikation`,
        name: 'vb-verification',
        component: VbVerificationView,
        beforeEnter: isNotAuthorizedRepresentative // this route is only accessable when the user is logged in. if not, he will be redirected to keycloak to log in
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vb-verifikation-fehler`,
        name: 'vb-error',
        component: VbErrorView,
        beforeEnter: isAuthenticated // this route is only accessable when the user is logged in. if not, he will be redirected to keycloak to log in
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifiziert`,
        name: 'vb-verified',
        component: VbVerifiedView,
        beforeEnter: isAuthenticated // this route is only accessable when the user is logged in. if not, he will be redirected to keycloak to log in
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/', // this is crucial in order to rewrite links to the single-spa container!
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (!to.hash) {
            window.scroll(0, 0)
        }
    }
})

export default router
