export function onValidTokenExists ({ commit }, data) {
    commit('ON_VALID_TOKEN_EXISTS', data)
}

export function onKeycloakTokenUpdateSuccess ({ commit }, data) {
    commit('ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS', data)
}

export function setActForMember ({ commit }, data) {
    commit('SET_ACT_FOR_MEMBER', data)
}