<template>
    <div>
        <div class="container pt">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <a href="/">{{ $t('shared.breadcrumbs.home') }}</a>
                            </li>
                            <li>
                                <a v-bind="{ 'href': $i18n.locale === 'en_US' ? '/en/my-profile' : '/mein-profil' }">
                                    {{ $t('appname') }}
                                </a>
                            </li>
                            <li>{{ $t('breadcrumbs.digitalIdentity') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand-light-grey">
            <div class="container pt">
                <div class="row">
                    <div class="col-12">
                        <div class="bg-white space-around mb">
                            <h1 data-qa="headline">{{ $t('vbstatus.h1') }}</h1>
                            <img src="@/assets/img/crefotrust-logo.svg" alt="CrefoTrust Logo" class="ct-logo" />

                            <div class="crefo-ui-alert error mb" v-html="$t('crefotrust.error.CANCELLED')" />

                            <router-link :to="{ name: 'vb-verification' }" class="btn btn-default" data-qa="crefotrust--authorized-representative--restart-verification-btn">Erneut starten</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VbErrorView'
}
</script>

<style lang="less">
.crefo-ui-alert.error a {
    color: #fff !important;
    text-decoration: underline;
}
.crefo-tooltip {
    width: 300px;
}
.flex {
    display: flex;
}
.space-around {
    padding: 15px;
}
.ct-logo {
    width: 150px;
    position: absolute;
    right: 30px;
    top: 30px;
    display: none;
}
@media only screen and (min-width: 768px) {
    .ct-logo {
        display: block;
    }
}
@media only screen and (max-width: 767px) {
    .teaser-image {
        margin-bottom: 30px;
    }
}

.verified {
    span {
        line-height: 23px;
        padding-left: 15px;
    }
}
</style>
