import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de_DE',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de_DE',
    messages: loadLocaleMessages(),
    silentTranslationWarn: true
})

function loadLocaleMessages () {
    const locales = require.context('./i18n', true, /([a-z]{2})+[_]+([A-Z]{2})+\.json$/)
    const messages = {}

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)

            if (window.sharedTranslations[locale]) {
                for (let i in window.sharedTranslations[locale]) {
                    messages[locale][`shared.${i}`] = window.sharedTranslations[locale][i]
                }
            }
        }
    })
    return messages
}

export default i18n
