<template>
    <span
        class="crefo-ui-tooltip"
        :class="positionClass"
        data-crefo-ui="true"
    >
        <span class="inline-wrapper">
            <slot></slot>
            <span class="crefo-tooltip">{{ text }}</span>
        </span>
    </span>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        text: {
            type: String,
            required: true
        },
        position: {
            type: String,
            required: false,
            default: 'top',
            validator: value => {
                return ['top', 'right', 'bottom', 'left'].includes(value)
            }
        }
    },
    computed: {
        positionClass () {
            return `tooltip-${this.position}`
        }
    }
}
</script>

<style lang="less">
/* Don't inherit button styles */
.btn span.crefo-tooltip {
    line-height: initial;
    text-transform: initial;
    font-weight: initial;
}
</style>
