<template>
    <div>
        <div class="container pt">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <a href="/">{{ $t('shared.breadcrumbs.home') }}</a>
                            </li>
                            <li>
                                <a v-bind="{ 'href': $i18n.locale === 'en_US' ? '/en/my-profile' : '/mein-profil' }">
                                    {{ $t('appname') }}
                                </a>
                            </li>
                            <li>{{ $t('breadcrumbs.digitalIdentity') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-brand-light-grey">
            <div class="container pt">
                <div class="row">
                    <div class="col-12">
                        <div class="bg-white space-around mb">
                            <h1 data-qa="headline">{{ $t('vbstatus.h1') }}</h1>
                            <img src="@/assets/img/crefotrust-logo.svg" alt="CrefoTrust Logo" class="ct-logo" />

                            <div v-if="isVerifying && !isAuthorizedRepresentative" data-qa="crefotrust--authorized-representative--verifying-role" class="crefo-ui-alert info flex verified">
                                <i class="crefo-ui-icon icon-circle-info" aria-hidden="true"></i>
                                <span>{{ $t('crefotrust.authorizedRepresentative.verifying') }}</span>
                            </div>

                            <div v-if="!isVerifying && isAuthorizedRepresentative" data-qa="crefotrust--authorized-representative--verified" class="crefo-ui-alert success flex verified">
                                <i class="crefo-ui-icon icon-check" aria-hidden="true"></i>
                                <span>{{ $t('crefotrust.authorizedRepresentative.verified') }}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dispatchEvent } from '@/helper/polyfill'

export default {
    name: 'VbVerifiedView',
    data () {
        return {
            isAuthorizedRepresentative: false,
            isVerifying: true
        }
    },
    mounted () {
        if (!this.$store.state.userModule.user.tokenParsed?.cr_userrole.filter((role) => role.toLowerCase().indexOf('vertretungsberechtigung') !== -1).length) {
            console.log('[DID] VB View: Rolle existiert nicht, forciere Tokenupdate..')

            // Force a token update, first.
            // This is required for users who view the page for the first time, 
            // after they've finished the verification process.
            dispatchEvent('updateKeycloakToken')

            /* istanbul ignore next */
            this.$store.watch((state) => state.userModule.user.token, (newProps, oldProps) => {
                if (newProps && newProps !== oldProps) {
                    this.isVerifying = false
                    console.log('[DID] VB View: Token aktualisiert..')

                    if (this.$store.state.userModule.user.tokenParsed.cr_userrole.filter((role) => role.toLowerCase().indexOf('vertretungsberechtigung') !== -1).length) {
                        this.isAuthorizedRepresentative = true
                        console.log('[DID] VB View: Rolle existiert.')
                    } else {
                        console.log('[DID] VB View: Rolle existiert nicht. Leite auf DID Startseite..')
                        this.$router.push({ name: 'vb-verification' })
                    }
                }
            })
        } else {
            console.log('[DID] VB View: Rolle existiert bereits, kein Tokenupdate notwendig.')
            this.isAuthorizedRepresentative = true
            this.isVerifying = false
        }
    }
}
</script>

<style lang="less">
.flex {
    display: flex;
}
.space-around {
    padding: 15px;
}
.ct-logo {
    width: 150px;
    position: absolute;
    right: 30px;
    top: 30px;
    display: none;
}
@media only screen and (min-width: 768px) {
    .ct-logo {
        display: block;
    }
}

.verified {
    span {
        line-height: 23px;
        padding-left: 15px;
    }
}
</style>
