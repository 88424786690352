import axios from 'axios'

/**
 * Before any request, we will check the lifetime of the Keycloak token.
 * If it's already expired or expiring soon (within the next 10 seconds),
 * we will force a token update before sending the request.
 */
axios.interceptors.request.use(
    async (config) => {
        if (window.keycloak && window.keycloak.token) {
            let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
            if (tokenExpires < 10) {
                console.log('[axios] Token expires soon. Updating token before sending request')
                await window.updateKeycloakToken()
            } else {
                console.log('[axios] Token still valid. No need to refresh before sending request')
            }
            config.headers.Authorization = `Bearer ${window.keycloak.token}`
        }

        if (sessionStorage.getItem('actForMemberId') !== null) {
            config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
        } else {
            delete config.headers['X-Act-For-Member-Id']
        }

        return config
    },
    (error) => Promise.reject(error)
)

/**
 * Initiiert das Platzieren eines Auftrags bei CrefoTrust
 * 
 * @param {string} apiBaseUrl - The base URL of the digital identity REST API
 * @param {*} order 
 * @returns {Promise<AxiosResponse<T>>} - Axios promise object
 */
export const placeOrder = (apiBaseUrl, order) =>
    axios.post(`${apiBaseUrl}/orders`, order)

