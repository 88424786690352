export function ON_VALID_TOKEN_EXISTS (state, data) {
    state.user.authenticated = true
    state.user.token = data.token
    state.user.tokenParsed = data.tokenParsed
    state.user.idTokenParsed = data.idTokenParsed
    state.user.user.email = data.idTokenParsed.email
    state.user.user.givenName = data.idTokenParsed.given_name
    state.user.user.surname = data.idTokenParsed.family_name
    state.user.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.user.id = data.idTokenParsed.cr_id
    state.user.user.memberId = data.idTokenParsed.cr_membernumber
}

export function ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS (state, data) {
    state.user.token = data.token
    state.user.tokenParsed = data.tokenParsed
    state.user.idTokenParsed = data.idTokenParsed
    state.user.user.email = data.idTokenParsed.email
    state.user.user.givenName = data.idTokenParsed.given_name
    state.user.user.surname = data.idTokenParsed.family_name
    state.user.user.user_roles = data.idTokenParsed.cr_userrole
    state.user.user.memberId = data.idTokenParsed.cr_membernumber
}

export function SET_ACT_FOR_MEMBER (state, data) {
    state.user.user.actForMemberId = data.actForMemberId
    state.user.user.actForMemberName = data.actForMemberName
}
